<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('psinForm')"
        @cancel="cancel">
    </add-header>
    <el-form ref="psinForm" :rules="rules" :model="psinForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="入库编号" prop="psin_no">
            <el-input v-model="psinForm.psin_no" maxlength="10"  show-word-limit placeholder="请填写入库编号">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="入库时间" prop="psin_time">
            <el-date-picker
              v-model="psinForm.psin_time"
              type="date"
              placeholder="选择入库时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <el-button type="primary" plain size="small" @click="addRow(0)">导入成品待入库</el-button>
        <el-button type="primary" plain size="small" @click="addRow(1)">导入产品</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
      </div>
      <el-row class="vd_mrt">
        <PsinAddEditIn @handleSelectionChange="handleSelect" :psinForm="psinForm"></PsinAddEditIn>
      </el-row>
      <el-dialog
          :title="title"
          :visible.sync="notice"
          width="70%">
        <PsinAddEditPpin @confirmIn="confirmIn" ref="PsinAddEditPpin"></PsinAddEditPpin>
      </el-dialog>
      <el-dialog
          :title="title"
          :visible.sync="notice1"
          width="70%">
        <PsinAddEditProd @confirmIn="confirmIn" ref="PsinAddEditProd"></PsinAddEditProd>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";
import PsinAddEditPpin from "@/views/StockManagement/PsinManage/TabChild/Component/PsinAddEditPpin";
import PsinAddEditProd from "@/views/StockManagement/PsinManage/TabChild/Component/PsinAddEditProd";
import PsinAddEditIn from "@/views/StockManagement/PsinManage/TabChild/Component/TabPanel/PsinAddEditIn";
import {psinAPI} from "@api/modules/psin";
export default {
  name: "PsinAddMain",
  components: {
    addHeader,
    inputUser,
    PsinAddEditIn,
    PsinAddEditPpin,
    PsinAddEditProd,
  },
  data(){
    return{
      psinForm:{
        psin_no:'',
        psin_time:'',
        psin_prod_list:[],
        key:0,
      },
      rules:{
        psin_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        psin_time:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
      },
      removeList:[],
      psinList:[],
      notice:false,
      title:'',
      notice1:false,
    }
  },
  created() {
  },
  methods:{
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },
    //保存
    saveInfo() {
      const staffForm =  this.$refs.userData.getData()
      let psinForm = {};
      psinForm = Object.assign(psinForm,this.psinForm,staffForm);
      psinForm.psin_time = (new Date(psinForm.psin_time)).getTime() / 1000;
      post(psinAPI.addPsin,psinForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              const permId = this.$route.query.perm_id
              this.jump(`/psin_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
              this.resetForm('psinForm')
            }else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'单据编号重复'
              })
              this.psinForm.psin_date = (new Date(this.psinForm.psin_date)).getTime() * 1000;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
              this.psinForm.psin_date = (new Date(this.psinForm.psin_date)).getTime() * 1000;
            }
          }).catch((res) =>{
            let msg = res.data.msg 
            this.$message.error(msg)
          })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('psinForm')
        this.$router.go(-1)
        this.$message({ 
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(()=>{})
    },
    //删除选择的内容
    handleSelect(val){
      this.removeList = val;
    },
    // 删除物料信息按钮
    delBank(){
      for(let i=0;i<this.removeList.length;i++){
        if(this.psinForm.psin_prod_list.indexOf(this.removeList[i]) !== -1){
          let index = this.psinForm.psin_prod_list.indexOf(this.removeList[i]);
          this.psinForm.psin_prod_list.splice(index,1)
        }
      }
    },
    // 打开导入物料信息小窗口
    addRow(num){
      if(num === 0){
        this.notice = true;
      }else if(num === 1){
        this.notice1 = true;
      }
    },
    // 导入小窗口确定按钮
    confirmIn(val){
      this.notice = false;
      this.notice1 = false;
      if(val.length>0){
        this.psinList = val;
        let psinListCopy = [];
        for(let i=0;i<this.psinList.length;i++){
          let temp = {};
          if(this.psinList[i].ppin_id){
            temp.ppin_id = this.psinList[i].ppin_id;
            temp.stay_ppin_num = parseInt(this.psinList[i].pcon_prod_num)-parseInt(this.psinList[i].ppin_num);
          }
          temp.prod_id = this.psinList[i].prod_id;
          temp.prod_no = this.psinList[i].prod_no;
          temp.prod_name = this.psinList[i].prod_name;
          temp.prod_spec = this.psinList[i].prod_spec;
          temp.prod_type = this.psinList[i].prod_type;
          psinListCopy.push(temp)
        }
        this.psinForm.psin_prod_list = this.psinForm.psin_prod_list.concat(psinListCopy);
        if(this.psinList[0].ppin_id){
          this.$refs.PsinAddEditPpin.clear()
        }else{
          this.$refs.PsinAddEditProd.clear()
        }
      }
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.psinForm.psin_prod_list = [];
    },
  }
}
</script>

<style scoped lang="scss">
.vd_mrt{
  margin-top: 20px;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
</style>